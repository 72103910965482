import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11')
];

export const server_loads = [0];

export const dictionary = {
		"/": [3],
		"/(signup)/accept-invite/[organisationId]/[code]": [4,[2]],
		"/complete-signup/[code]": [6],
		"/create-password/[code]": [7],
		"/login": [~8],
		"/logout": [~9],
		"/reset-password": [10],
		"/(signup)/signup": [~5,[2]],
		"/verify-email/[code]": [~11]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';